import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import liff from "@line/liff";
import { LiffMockPlugin } from "@line/liff-mock";
import "./App.css";
import RegistrationForm from "./pages/RegistrationForm";
import CouponList from "./pages/CouponList";
import StampCard from "./pages/StampCard";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ApprovalOutlinedIcon from "@mui/icons-material/ApprovalOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { BottomNav, BottomNavItem } from "./components/styles";
import MyPage from "./pages/MyPage";
import StampCardList from "./pages/StampCardList";
import EditProfileForm from "./pages/EditProfileForm";
import GetCouponList from "./pages/GetCouponList";
import axios from "./service/AxiosBaseService";
import useUserStore from "./store/userStore";
import { User } from "./components/types";
import useAuthStore from "./store/authStore";

function App() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, setIsAuthenticated } = useAuthStore();
  const { setUser } = useUserStore();

  useEffect(() => {
    const liffId = process.env.REACT_APP_LIFF_ID;

    if (!liffId) {
      console.error("LIFF_ID is not defined in environment variables");

      return;
    }

    const liffConfig = {
      liffId: liffId,
    };

    liff
      .init(liffConfig)
      .then(() => {
        if (!liff.isLoggedIn()) {
          console.log("loginerror");
          return liff.login();
        }
      })
      .then(() => {
        const accessToken = liff.getAccessToken();
        if (accessToken) {
          axios
            .post("/users/login", { accessToken }, { withCredentials: true })
            .then((response) => {
              if (response.data.exists) {
                setUser(response.data.user);
                setIsAuthenticated(true);
                setLoading(false);
                navigate("/mypage", { replace: true });
              } else {
                setLoading(false);
                navigate("/");
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Login Error:", error);
              navigate("/");
            });
        } else {
          console.warn("Access token not found.");
          navigate("/");
        }
      });
  }, []);

  useEffect(() => {
    if (location.pathname === "/mypage") {
      setValue(0);
    } else if (location.pathname === "/coupon") {
      setValue(1);
    } else if (location.pathname === "/stamp") {
      setValue(2);
    }
  }, [location.pathname]);

  return (
    <div className="App">
      {loading ? (
        <div className="loading-container">
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <Routes>
            {isAuthenticated ? (
              <>
                <Route path="/" element={<MyPage />} />
                <Route path="/mypage" element={<MyPage />} />
                <Route path="/mypage/edit" element={<EditProfileForm />} />
                <Route path="/mypage/coupon" element={<GetCouponList />} />
                <Route path="/coupon" element={<CouponList />} />
                <Route path="/stamp" element={<StampCard />} />
                <Route path="/stamp/index" element={<StampCardList />} />
              </>
            ) : (
              <Route path="/" element={<RegistrationForm />} />
            )}
          </Routes>
          {isAuthenticated ? (
            <BottomNav
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavItem
                label="マイページ"
                onClick={() => {
                  setValue(0);
                  navigate("/mypage", { replace: true });
                }}
                icon={<PersonIcon />}
              />
              <BottomNavItem
                label="クーポン"
                onClick={() => {
                  setValue(1);
                  navigate("/coupon", { replace: true });
                }}
                icon={<ConfirmationNumberOutlinedIcon />}
              />
              <BottomNavItem
                label="スタンプ"
                onClick={() => {
                  setValue(2);
                  navigate("/stamp", { replace: true });
                }}
                icon={<ApprovalOutlinedIcon />}
              />
            </BottomNav>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default App;
